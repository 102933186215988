import { ApiFetchError, ResponseInfo } from "./utils/ApiFetchError";
import { GlobalErrorType } from "./utils/GlobalErrorType";
import { apiUrl } from "~/config";
import { globalStore } from "~/store/initStore";
import { accessToken } from "~/store/login/loginReducer";

export interface ApiErrorResponse {
  type: string;
  description: string;
  message?: string;
}

export const AUTH_HEADER = "Authorization";

export function fetchApi(endpoint: string, args?: RequestInit) {
  const token = accessToken(globalStore.getState());
  let authorization = args?.headers?.[AUTH_HEADER];
  if (!authorization && token) authorization = `Bearer ${token}`;
  return fetch(apiUrl + endpoint, {
    method: "GET",
    ...args,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(args && args.headers),
      [AUTH_HEADER]: authorization || undefined,
    },
  });
}

export function toJson(response: Response) {
  return response.json();
}

export function requireOk(response: Response) {
  if (response.ok) {
    return response;
  } else {
    return response
      .json()
      .catch(() => null)
      .then((json) => {
        const info: ResponseInfo = {
          status: response.status,
        };

        if (response.status === 401) {
          throw new ApiFetchError(
            `Session expired.`,
            `session expired`,
            GlobalErrorType.NotAuthorized,
            info
          );
        }

        if (!json) {
          throw new ApiFetchError(
            `An error occured`,
            `response failed`,
            undefined,
            info
          );
        }

        const message = json && json.message;
        const publicMessage =
          `Response failed` + (message ? `: ${message}` : "");
        throw new ApiFetchError(publicMessage, message, undefined, info);
        return response;
      });
  }
}
