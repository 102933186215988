import { fetchLogin } from "../../services/loginService";
import { setLocalVar } from "../../utils/js/localStore";
import {
  LoginRequestBody,
  LoginResponseBody,
} from "../../@core/types/api/loginRouteTypes";
import { setLastProjectIds } from "../../elements/context/project/ProjectSelectionContext";
import { LoginState, LoginUserState } from "./loginReducer";

export function requestLogin(args: LoginRequestBody) {
  return (dispatch) => {
    return fetchLogin(args).then((result: LoginResponseBody) => {
      setLocalVar("token", result.token);
      dispatch(
        setLoggedIn({
          token: result.token,
          user: {
            id: result.user.id,
            customerId: result.user.customerId,
            name: result.user.fullName || result.user.loginName,
            role: result.user.role,
          } as LoginUserState,
        })
      );
    });
  };
}

export const SET_LOGGED_IN = "SET_LOGGED_IN";
export function setLoggedIn(args: LoginState) {
  return {
    type: SET_LOGGED_IN,
    payload: args,
  };
}

export const SET_LOGGED_OUT = "SET_LOGGED_OUT";
export function setLoggedOut() {
  setLastProjectIds(undefined);
  return {
    type: SET_LOGGED_OUT,
  };
}
