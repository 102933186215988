import { randomInt as _randomInt } from "./js/randomUtils";

export function optionalInvoke<T>(objOrFn: T | (() => T)): T {
  return objOrFn instanceof Function ? objOrFn() : objOrFn;
}

export function limit(size: number) {
  return (_, index) => index < size;
}

export function limitList<T>(arr: T[], size: number): T[] {
  return arr?.filter(limit(size));
}

export function setQueryParam(name: string, value: any, push = false) {
  const params = new URLSearchParams(location.search);
  if (value === undefined || value === null) {
    params.delete(name);
  } else {
    params.set(name, value);
  }
  const query = params.toString();

  const data = {};
  const title = "";
  const url = location.pathname + `${query ? `?${query}` : ""}`;

  if (push) {
    window.history.pushState(data, title, url);
  } else {
    window.history.replaceState({}, "", url);
  }
}

export function setQueryParams(obj: { [key: string]: string | number }) {
  const params = new URLSearchParams(location.search);
  Object.entries(obj).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      params.delete(key);
    } else {
      params.set(key, value.toString());
    }
  });
  window.history.replaceState({}, "", `${location.pathname}?${params}`);
}

export function getQueryParam<T = string>(
  name: string,
  transform?: (val: string) => T
): T {
  if (typeof window === "undefined") return undefined;
  const params = new URLSearchParams(location.search);
  const param = params.get(name);
  return transform ? transform(param) : (param as unknown as T);
}

export function getQueryParams() {
  return new URLSearchParams(location.search);
}

export function queryParams(obj: Record<string, any>) {
  return Object.entries(obj)
    .map(([key, value]) => {
      if (value === null || value === undefined) return null;
      return encodeURIComponent(key) + "=" + encodeURIComponent(value);
    })
    .filter(notNull)
    .join("&");
}

export function withQueryParams(url: string, obj: Record<string, any>) {
  const params = queryParams(obj);
  if (!params?.length) return url;
  if (url.indexOf("?") === -1) return `${url}?${params}`;
  else return `${url}&${params}`;
}

export const isNull = (val) => val === undefined || val === null;

export function notNull<T = any>(val: T): boolean {
  return !!val;
}

export function tryClickOnElement(id) {
  const element = document.getElementById(id);
  if (element) {
    element.click();
    return true;
  } else return false;
}

export const stringify = (value) => value && `${value}`;

export function omit<T>(obj: T, key: keyof T) {
  const copy = Object.assign({}, obj);
  delete copy[key];
  return copy;
}

export function isEmpty(obj: Record<string, any>) {
  return Object.keys(obj).length === 0;
}

export function shorten(str: string, max: number) {
  if (!str) return str;
  if (str.length > max) return str.substr(0, max - 1) + " ...";
  return str;
}

export function toBase64(str: string) {
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode(Number(`0x${p1}`));
      }
    )
  );
}

export function fromBase64(encoded: string) {
  return decodeURIComponent(
    atob(encoded)
      .split("")
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join("")
  );
}

export function createEmailHref(subject: string, body: string) {
  return `mailto:?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
}

export function getBaseUrl(path = "") {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}:${port}${path}`;
}

export const toInt = (number: string) => parseInt(number);
export const toIntArray = (numbers: string, delim = ",") =>
  numbers?.split(delim).map(toInt);
export const toDate = (date?: string | number) => date && new Date(date);
export const toBool = (any: string) => any === "1";

export function thenWait(ms) {
  return (any) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(any);
      }, ms);
    });
  };
}

export const randomInt = _randomInt;

export function mapN<T = any>(count: number, generator: (i: number) => T) {
  const array = [];
  for (let i = 0; i < count; i++) {
    array.push(generator(i));
  }
  return array;
}

export function round(number: number, digits = 2) {
  const factor = 10 ^ digits;
  return Math.round(number * factor) / factor;
}

export const EMPTY_STRING = "-";
export const EMPTY_ARRAY = [];

export function strToHash(str: string) {
  return str
    .split("")
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0
    );
}

export function keyBy<T, K extends string | number | symbol>(
  list: T[],
  getKey: (obj: T) => K
) {
  return list?.reduce((result, item) => {
    const key = getKey(item);
    result[key] = item;
    return result;
  }, {} as Record<K, T>);
}

export function sum<T>(
  items: T[],
  extractValue: (item: T) => number = (i) => i as any as number
) {
  return items?.reduce((result, item) => {
    const value = extractValue(item);
    return result + value;
  }, 0);
}

export function sort<T>(items: T[], compareFn?: (a: T, b: T) => number) {
  items?.sort(compareFn);
  return items;
}

export function sortBy<T>(
  items: T[],
  getKey: (obj: T) => number | string,
  reverse = false
) {
  if (!items) return items;
  return [...items].sort((a, b) => {
    const valueA = getKey(a);
    const valueB = getKey(b);
    if (reverse) return valueB > valueA ? 1 : -1;
    else return valueA > valueB ? 1 : -1;
  });
}

export function copyToClipboard(content: string) {
  return navigator.clipboard.writeText(content);
}

export function isPromise(pet: Promise<any> | any): pet is Promise<any> {
  return (pet as Promise<any>).then !== undefined;
}

export function ensureArray<T>(arrOrItem: T | T[]): T[] {
  if (arrOrItem === null) return null;
  if (arrOrItem === undefined) return undefined;
  if (Array.isArray(arrOrItem)) return arrOrItem;
  return [arrOrItem];
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
