import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { WithChildren } from "../types/reactTypes";
import initStore from "./initStore";

type AppWithStoreProps = WithChildren;

const store = initStore();

class AppWithStore extends React.PureComponent<AppWithStoreProps> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
          {this.props.children}
        </PersistGate>
      </Provider>
    );
  }

  props: AppWithStoreProps;
}

export default AppWithStore;
