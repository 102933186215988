import { DebugOptionState } from "./debugReducer";

type SetDebugAction = Partial<DebugOptionState>;

export const SET_DEBUG_OPTION = "SET_DEBUG_OPTION";
export function setDebugOption(args: SetDebugAction) {
  return {
    type: SET_DEBUG_OPTION,
    payload: args,
  };
}
