import {
  fetchProjects,
  fetchSimpleProjectStats,
  fetchSingleProjectStats,
} from "../../services/projectService";
import { alertError } from "../../utils/alert/alertUtils";
import { FetchError } from "../../services/utils/ApiFetchError";
import { GlobalErrorType } from "../../services/utils/GlobalErrorType";
import { setLoggedOut } from "../login/loginActions";
import {
  DetailedProjectStats,
  ProjectId,
  ProjectStats,
  ProjectType,
  SimpleProjectStats,
} from "../../@core/types/domain/ProjectType";
import { KeyBy } from "../../types/tsUtils";
import { allProjects, projectStats } from "./projectReducer";

let fetchingProjects = false;

export function requestProjects() {
  return (dispatch, getState) => {
    if (fetchingProjects) return null;
    const projects = allProjects(getState());
    if (projects) return;
    fetchingProjects = true;
    fetchProjects()
      .then((projects) => {
        dispatch(setProjects(projects));
      })
      .catch((err: FetchError) => {
        if (err.type === GlobalErrorType.NotAuthorized) {
          // TODO this needs to be outsourced and applied for every request
          dispatch(setLoggedOut());
        }
        alertError("Failed fetching projects");
      })
      .then(() => {
        fetchingProjects = false;
      });
  };
}

const statsFetchedLock = {};
export function requestProjectStats(projectId: ProjectId) {
  return (dispatch, getState) => {
    const stats = projectStats(getState());
    if (stats[projectId] || statsFetchedLock[projectId]) {
      // stats are cached
      return;
    }
    statsFetchedLock[projectId] = "fetching";
    fetchSingleProjectStats(projectId)
      .then((value) => {
        dispatch(setProjectStats(projectId, value));
      })
      .catch((err) => {
        console.error(err);
      })
      .then(() => {
        delete statsFetchedLock[projectId];
      });
  };
}

export const SET_PROJECT_STATS = "SET_PROJECT_STATS";
export function setProjectStats(
  projectId: ProjectId,
  stats: DetailedProjectStats
) {
  return {
    type: SET_PROJECT_STATS,
    payload: stats,
    meta: projectId,
  };
}

export function requestSimpleProjectStats() {
  return (dispatch, getState) => {
    // TODO caching?
    fetchSimpleProjectStats()
      .then((value) => {
        dispatch(setSimpleProjectStats(value));
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export const SET_SIMPLE_PROJECT_STATS = "SET_SIMPLE_PROJECT_STATS";
export function setSimpleProjectStats(stats: KeyBy<SimpleProjectStats>) {
  return {
    type: SET_SIMPLE_PROJECT_STATS,
    payload: stats,
  };
}

export const SET_PROJECTS = "SET_PROJECTS";

export function setProjects(projects: ProjectType[]) {
  return {
    type: SET_PROJECTS,
    payload: projects,
  };
}
