import { GlobalErrorType } from "./GlobalErrorType";
import { ExtendableError } from "~/utils/js/ExtendableError";

export type FetchError = ApiFetchError;

export class ApiFetchError extends ExtendableError {
  public publicMessage?: string;
  public type?: GlobalErrorType;
  public status?: number;

  constructor(
    publicMessage = "unknown error",
    message: string = publicMessage,
    type: GlobalErrorType = null,
    info?: ResponseInfo
  ) {
    super(message);
    this.publicMessage = publicMessage;
    this.type = type;
    this.status = info?.status;
  }
}

export interface ResponseInfo {
  status?: number;
}
