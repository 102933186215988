import React from "react";
import { RecoilRoot } from "recoil";
import AppWithStore from "./store/AppWithStore";
import { WithChildren } from "./types/reactTypes";
import AppWithToast from "./utils/alert/AppWithToast";

type AppProps = WithChildren;

function AppWrapper(props: AppProps) {
  return (
    <AppWithToast>
      <RecoilRoot>
        <AppWithStore>{props.children}</AppWithStore>
      </RecoilRoot>
    </AppWithToast>
  );
}

export function wrapGlobalApp({ element }) {
  return <AppWrapper>{element}</AppWrapper>;
}
