import React, { createContext, useContext } from "react";
import {
  EMPTY_ARRAY,
  getQueryParam,
  setQueryParam,
  toInt,
} from "../../../utils/jsUtils";
import { ProjectId } from "../../../@core/types/domain/ProjectType";
import { ProjectSelection } from "./ProjectSelection";

type SelectionUpdater = (selection: ProjectSelection) => ProjectSelection;

interface ProjectSelectionState {
  selection: ProjectSelection;
  setSelection: (update: SelectionUpdater | ProjectSelection) => void;
}

const context = createContext<ProjectSelectionState>(null);
const Provider = context.Provider;

interface ProjectSelectionContextProps {
  resolveFromUrl?: boolean;
  initialSelection?: ProjectSelection;
}

let lastProjectIds: ProjectId[];

export function setLastProjectIds(projectId: ProjectId[]) {
  lastProjectIds = projectId;
}

export class ProjectSelectionContext extends React.PureComponent<ProjectSelectionContextProps> {
  state: ProjectSelection = {
    projectIds: [],
  };
  setSelection = (updater: SelectionUpdater | ProjectSelection) => {
    this.setState(updater, () => {
      const { projectIds } = this.state;
      setQueryParam(
        queryParameter,
        projectIds?.length > 0 ? projectIds.join(",") : undefined
      );
      lastProjectIds = projectIds;
    });
  };

  constructor(props: ProjectSelectionContextProps, context: any) {
    super(props, context);
    if (this.props.resolveFromUrl) {
      const query = getQueryParam(queryParameter);
      let ids = query?.split(",").map(toInt);
      if (!ids && lastProjectIds) {
        // if not forced by query param, take it from the last route
        ids = lastProjectIds;
        setQueryParam(queryParameter, ids);
      }
      this.state = { projectIds: ids || EMPTY_ARRAY };
    } else if (this.props.initialSelection) {
      this.state = {
        projectIds: EMPTY_ARRAY,
        ...props.initialSelection,
      };
    }
  }

  render() {
    return (
      <Provider
        value={{
          selection: this.state,
          setSelection: this.setSelection,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

const queryParameter = "project";

export const useProjectSelection = () => useContext(context);
