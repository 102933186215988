// @flow
import { SET_DEBUG_OPTION } from "./debugActions";

export interface DebugOptionState {
  general: boolean;
}

function debugReducer(state = {}, { type, payload }) {
  switch (type) {
    case SET_DEBUG_OPTION:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export const debugOptions = (state) => state["debug"] as DebugOptionState;

export default debugReducer;
