// @flow
import { combineReducers } from "redux";
import projectReducer from "./project/projectReducer";
import debugReducer from "./debug/debugReducer";
import loginReducer from "./login/loginReducer";

const rootReducer = combineReducers({
  project: projectReducer,
  debug: debugReducer,
  login: loginReducer,
});

export default rootReducer;
