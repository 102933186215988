import { createSelector } from "reselect";
import { UserRole } from "../../@core/types/domain/user/UserRole";
import { SET_LOGGED_IN, SET_LOGGED_OUT } from "./loginActions";

export interface LoginUserState {
  id: number;
  customerId: number;
  name: string;
  role: UserRole;
}

export interface LoginState {
  token?: string;
  user?: LoginUserState;
}

function loginReducer(state = {}, { type, payload }) {
  switch (type) {
    case SET_LOGGED_IN:
      return payload;
    case SET_LOGGED_OUT:
      return {};
    default:
      return state;
  }
}

const selector = (state) => state["login"] as LoginState;

export function userSelector(state) {
  const shape = selector(state);
  return shape && shape.user;
}

export const loginState = createSelector(selector, (state) => ({
  token: state.token,
  user: state.user,
  loggedIn: state.token && state.user,
}));

export const accessToken = createSelector(selector, (state) => state.token);

export function userRoleSelector(state) {
  const user = userSelector(state);
  // TODO remove default after 01.06.2020
  return user ? user.role || UserRole.Customer : null;
}

export default loginReducer;
